@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@font-face {
    font-family: Lato-Regular;
    src: url(../font/Lato-Regular.ttf);
}

body {
    margin: 0;
    padding: 0;
    font-family: Lato-Regular;
}

* {
    box-sizing: border-box;
}

:root {
    --primary: #D41C5A;
    --text-white: #ffffff;
    --black-color: #000000;
    --note-text: #f11010;
}

/* sidebar css start */

.nav-pills .nav-link.sidebar_links {
    color: var(--primary);
}

.nav-pills .nav-link.sidebar_links.active {
    background-color: var(--primary);
    color: var(--text-white);
}

.sidebar_align {
    background-color: var(--text-white);
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
}

/* sidebar css end */

/* form content css start */

table td,
th {
    font-size: 14px;
    /* border: 1px solid #000000; */
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px 15px;
}

table thead {
    text-align: center;
    background-color: rgb(241, 241, 241);
}

table tbody tr:nth-child(even) {
    background-color: rgb(241, 241, 241);
}

.form_content h5 {
    color: var(--primary);
    font-size: 16px;
}

.tab_cursor {
    cursor: pointer;
}

.body_content {
    margin-left: 300px;
    width: calc(100% - 300px);
}

.form_content {
    text-align: left;
}

.form_content .heading {
    font-weight: 600;
    text-align: center;
}

.common_btn {
    background-color: var(--primary);
    color: var(--text-white);
    border: none;
    border-radius: 20px;
    padding: 6px 20px;
    margin-left: 10px;
}

/* .hr_line {
    border-bottom: 1px solid #cecece;
} */

.tab_content .active {
    border-bottom: 2px solid var(--primary);
}

.tab_content .bg_align {
    border: 2px solid var(--primary);
    color: var(--primary);
    padding: 0px 8px;
    border-radius: 50%;
    font-size: 16px;
    transform: translateZ(0px);
    height: 30px;
    width: auto;
    text-align: center;
}

.tab_content .bg_align.active {
    background-color: var(--primary);
    color: var(--text-white);
}

.tab_content .col-auto {
    padding: 9px 13px;
}

.tab_content p {
    margin: 0;
    font-size: 14px;
}

.tab_content p.activetext {
    font-weight: 600;
}

.sub_heading {
    color: var(--primary);
    font-weight: 600;
}

input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=date]::placeholder,
input[type=email]::placeholder,
textarea::placeholder,
input[type=file]::placeholder,
input[type=text]:focus,
textarea:focus,
input[type=email]:focus,
input[type=date]:focus,
input[type=file]:focus {
    font-size: 14px;
    /* color: #bdbdbd; */
}

.form-label {
    margin-bottom: 0;
    word-wrap: break-word;
}

.label_head {
    font-weight: 600;
    font-size: 15px;
}

.text_area_height {
    height: 17vh;
}

.back_btn {
    background-color: var(--text-white);
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 5px 20px;
    border-radius: 10px;
}

.back_btn:hover {
    background-color: var(--primary);
    color: var(--text-white);
    border: 2px solid var(--primary);
}

.next_btn {
    background-color: var(--primary);
    color: var(--text-white);
    padding: 5px 20px;
    border: 2px solid var(--primary);
    border-radius: 10px;
}

.next_btn:hover {
    background-color: var(--text-white);
    color: var(--primary);
    border: 2px solid var(--primary);
}

.note_info {
    font-size: 12px;
    font-weight: 400;
}

.sub_sector,
.sub_sector:focus {
    border: none;
    border-bottom: 1px solid var(--black-color);
}

td {
    padding: 10px;
}

.info_red {
    color: var(--note-text);
    font-size: 20px;
}

.menu_icon,
.close_icon {
    display: none;
}

/* form content css end */

/* form 2 css start */

.lrn_number {
    letter-spacing: 10px;
}

/* form 2 css end */

@media (max-width: 768px) {

    .sidebar_align {
        left: -300px;
    }

    .sidebar_align_show {
        left: 0;
        z-index: 2;
    }

    .body_content {
        margin-left: 0px;
        width: 100%;
    }

    .label_head {
        margin-bottom: 8px;
    }

    .menu_icon,
    .close_icon {
        display: block;
    }

}

/* Chrome, Safari, Edge, Opera - remove Up and Down Arrow*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox - remove Up and Down Arrow */
input[type=number] {
    -moz-appearance: textfield;
}

.hidden-icon {
    position: absolute;
    top: 6px;
    right: 35px
}

.input-password {
    position: relative;
}

.disable{
    opacity: 1.5 !important;
    pointer-events: none;
 }