* {
    margin: 0;
    padding: 0;
}

.pdf_design p {
    margin: 0;
    padding: 5px 6px !important;
    font-size: 13px;
    white-space: pre-wrap;
}

:root {
    --black: #000000;
}

.fw-600 {
    font-weight: 600;
}

.fs-20 {
    font-size: 20px;
}

.hr_line {
    border-bottom: 0.5px solid var(--black);
    width: 80px;
    margin: 10px 0;
}

.width_auto,
.date_align p {
    width: auto;
    flex-grow: 1 !important;
}

.date_align {
    display: flex;
}

.width_auto:not(:first-child),
.date_align p:not(:first-child) {
    border-left: 0.5px solid var(--black) !important;
}

.w-20 {
    width: 20%;
}

.w-90 {
    width: 90%;
}

.pt-40 {
    padding-top: 40px;
}

.box_info .row,
.box_head,
.agreement_detail .row .box_head {
    border-top: 0.5px solid var(--black);
    border-left: 0.5px solid var(--black);
    border-right: 0.5px solid var(--black);
    margin: 0;
    padding: 0;
}

.agreement_detail .col-1,
.agreement_detail .col-2,
.agreement_detail .col-3,
.agreement_detail .col-4,
.agreement_detail .col-5,
.agreement_detail .col-6,
.agreement_detail .col-7,
.agreement_detail .col-8,
.agreement_detail .col-10,
.agreement_detail .col-11,
.agreement_detail .col-12 {
    border-top: 0.5px solid var(--black);
    border-left: 0.5px solid var(--black);
    margin: 0;
    padding: 0;
}

.agreement_detail .row .w-20:last-child,
.agreement_detail .row .col-1:last-child,
.agreement_detail .row .col-2:last-child,
.agreement_detail .row .col-3:last-child,
.agreement_detail .row .col-4:last-child,
.agreement_detail .row .col-5:last-child,
.agreement_detail .row .col-6:last-child,
.agreement_detail .row .col-7:last-child,
.agreement_detail .row .col-8:last-child,
.agreement_detail .row .col-10:last-child,
.agreement_detail .row .col-12:last-child,
.border_right {
    border-right: 0.5px solid var(--black);
}

.border-0 {
    border: none !important;
}

.border_top {
    border-top: 0.5px solid var(--black);
}

.border_left {
    border-left: 0.5px solid var(--black);
}

.border_left .border_bottom,
.box_info .row.last_box,
.agreement_detail .row:last-child {
    border-bottom: 0.5px solid var(--black);
}

.agreement_detail .row .row,
.agreement_detail .row .row .row {
    border-bottom: none !important;
}

.agreement_detail .w-20 {
    border-left: 0.5px solid var(--black);
    border-top: 0.5px solid var(--black);
    border-bottom: 0;
}

.box_info p {
    margin: 0;
    padding: 5px 0;
}

.agreement_detail table thead {
    background-color: transparent;
}

.agreement_detail tbody,
td,
tfoot,
th,
thead,
tr,
.border_all {
    border: 0.5px solid var(--black) !important;
}

.agreement_detail tbody tr:nth-child(even) {
    background-color: transparent;
}

.highlight_text {
    text-decoration: underline;
}